import React from "react";
import "./footer.scss";

export default function Footer() {
  return (
    <footer>
      <div className="footer__container">
        <div className="footer__links">
          <a href="/">Hjem</a>
          <a href="/register">Bli medlem</a>
          <a href="/terms">Personvern</a>
          <a href="/gdpr">Vilkår</a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <a href="https://www.facebook.com/profile.php?id=61550332981160">
            Facebook
          </a>
          {/* <a href="/">Instagram</a> */}
        </div>
        <div>
          <div>Kveldetunet 8</div>
          <div>3282 Kvelde</div>
          <div>E-post: post@kveldehelse.no</div>
          {/* <div>Tlf: (+47) 97 69 09 26</div> */}
        </div>
      </div>
    </footer>
  );
}
