import React from "react";
import "./style/main.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Footer from "./assets/footer";
import Terms from "./pages/terms";
import GDPR from "./pages/terms/Gdpr";

const FrontPage = React.lazy(() => import("./pages/index"));
const Register = React.lazy(() => import("./pages/register"));
const Payment = React.lazy(() => import("./pages/payment"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <FrontPage />
      </React.Suspense>
    ),
    loader: () => import("./pages/index"),
  },
  {
    path: "/register",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Register />
      </React.Suspense>
    ),
    loader: () => import("./pages/register"),
  },
  {
    path: "/terms",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Terms />
      </React.Suspense>
    ),
    loader: () => import("./pages/terms"),
  },
  {
    path: "/gdpr",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <GDPR />
      </React.Suspense>
    ),
    loader: () => import("./pages/terms/Gdpr"),
  },
  {
    path: "/payment",
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Payment />
      </React.Suspense>
    ),
  },
]);

function App() {
  return (
    <>
      <main>
        <nav>
          <div></div>
          <div className="nav__links">
            <a href="/">Hjem</a>
            <a href="/register">Registrer deg</a>
            <a href="/terms">Vilkår</a>
            <a href="/gdpr">Personvern</a>
          </div>
        </nav>
        <RouterProvider router={router} />
      </main>
      <Footer />
    </>
  );
}

export default App;
