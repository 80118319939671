import React from "react";

export default function Terms() {
  return (
    <div
      style={{
        position: "relative",
        maxWidth: "1000px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "3vh auto",
        padding: "2rem",
        gap: "1rem",
      }}
    >
      <h1>Medlemsvilkår</h1>
      <ul
        style={{
          position: "relative",
          width: "40rem",
          maxWidth: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          letterSpacing: "0.05rem",
          textAlign: "left",
          lineHeight: "1.5rem",
        }}
      >
        <li>
          Dersom du er under 18 år må du ha tillatelse fra foresatte for å kunne
          bli medlem. Dersom du er mellom 13 til 16 år må foresatte være med på
          treningen sammen med deg. Dersom du har noen spørsmål om dette kan du
          gjerne ta kontakt med oss.
        </li>
        <li>
          Kvelde Helsehus er et treningssenter for alle. Vi ønsker å skape et
          inkluderende miljø for alle våre medlemmer. Vi forventer at alle
          medlemmer oppfører seg på en måte som ikke er til sjenanse for andre
          medlemmer.
        </li>
        <li>
          Når det gjelder hygiene, tar vi ingen snarveier. Vi forventer at alle
          medlemmer respekterer de grunnleggende reglene for renslighet og
          hygiene. Maskiner og utstyr skal tørkes ned etter bruk med de
          desinfiserende midlene som er tilgjengelige. Vi ber om at du
          respekterer andre medlemmer av senteret, slik at vi sammen
          opprettholder et sunt, trygt og vennlig treningsmiljø.
        </li>
        <li>
          Medlemskapet er personlig og kan ikke overdras til andre. Medlemskapet
          er gyldig fra den dagen det er registrert og betalt. Medlemskapet
          fornyes automatisk hver måned. Medlemskapet kan sies opp med 3
          måneders oppsigelsestid. Oppsigelse må skje skriftlig til
          <a
            href="mailto:post@kveldehelse.no"
            style={{ color: "#3f3f3f", margin: "1rem" }}
          >
            post @ kveldehelse.no
          </a>
        </li>
        <li>
          Betalingen trekkes automatisk fra din konto hver måned. Dersom
          betalingen ikke kan gjennomføres vil medlemskapet bli sperret. Du er
          selv ansvarlig for at betalingen kan gjennomføres. Dersom betalingen
          ikke gjennomføres vil vi forsøke å kontakte deg for å løse problemet.
          Normalt vil vi ta kontakt via epost eller telefon. Dersom vi ikke får
          kontakt med deg vil medlemskapet bli avsluttet og du vil ikke lenger
          ha tilgang til treningssenteret.
        </li>
        <li>
          Som medlem har du ansvar for å holde deg oppdatert på våre
          medlemsvilkår på denne siden, og du er selv ansvarlig for å følge
          disse. Ett medlemskap gir tilgang til treningssenteret for en person.
          Det er ikke tillatt å slippe inn andre personer som ikke er medlemmer.
          Dersom du bryter medlemsvilkårene kan medlemskapet bli avsluttet uten
          refusjon.
        </li>
        <li>
          Som medlem ved Kvelde Helsehus er du pliktig til å følge
          instruksjonene fra Antidoping Norge. Antidoping Norge har rett til å
          gjennomføre dopingkontroll på vårt treningssenter. Dersom du ikke
          følger instruksjonene fra Antidoping Norge vil du bli utestengt fra
          treningssenteret, som medelem er du pliktig til å følge instruksjonene
          og må godta dopingkontroll.
        </li>
      </ul>
    </div>
  );
}
