import React from "react";

export default function GDPR() {
  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "40rem",
          maxWidth: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          letterSpacing: "0.05rem",
          textAlign: "left",
          lineHeight: "1.5rem",
          padding: "2rem 0px",
        }}
      >
        <h1>Personvernserklæring</h1>
        <p>
          Kvelde Helsehus er behandlingsansvarlig for bruk og distribusjon av
          dine personopplysninger. Under finner du all informasjon om
          opplysningene du har krav på når du bruker våre tjenester.
        </p>
        <p>
          Ved å benytte våre tjenester så godtar du at vi behandler dine
          personopplysninger i henhold til denne personvernerklæringen. Vi,
          Kvelde Helsehus, benytter dine personopplysninger for å kunne tilby
          deg treningsfasiliteter og andre tjenester som vi tilbyr. Vi tar vare
          på dine personopplysninger nøyaktig slik du oppgir i
          registreringsskjemaet for å kunne følge opp tilgang til
          treningsområdet og betalings tjenestene.
        </p>
        <p>
          Dine opplysninger vil ikke bli delt med andre tredjeparter uten ditt
          samtykke. Vi vil ikke selge dine personopplysninger til tredjeparter.
          All data blir lagret innenfor EU/EØS-området. Vi vil ikke lagre dine
          personopplysninger lenger enn det som er nødvendig for å oppfylle
          formålet med tilgang til treningssenteret, og aldri lenger enn det er
          lovpålagt å lagre opplysningene.
        </p>
        <p>
          Du har rett til å be om innsyn i de personopplysningene vi har om deg,
          og du kan be om at vi retter eller sletter personopplysninger. Du kan
          også be om å få utlevert personopplysninger ved å kontakte oss på
          epost: post@kveldehelse.no
        </p>
        <p>
          Vi har ikke noen tredjeparts cookies eller tracking i forhold til
          aktivitet eller hvem du er. Dette er ikke vesentlig informasjon for
          oss, og vi verdsetter ditt personvært så du kan trygt bruke våre
          tjenester uten å bli målt, analysert eller tracket av andre selskaper.
          Vi selger aldri dine opplysninger. Det er dine opplysninger.
        </p>
      </div>
    </div>
  );
}
